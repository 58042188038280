import React, {MouseEventHandler, useState} from 'react';
import * as Sentry from "@sentry/react";
import logo from './logo.png';

function App() {
  const [deploy, setDeploy] = useState('');
  const [stage, setStage] = useState('');
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [password, setPassword] = useState('');

  const [statusCode, setStatusCode] = useState('');
  const [response, setResponse] = useState('');

  const selector = '*';

  const onDelete: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault()
    const request = new Request(
      `https://api.display-cms.adapta.ca/${deploy}/${stage}/${selector}`, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Authorization': `Bearer ${password}`
        }
      }
    );

    try {
      const response = await fetch(request)

      setStatusCode(`${response.status}`)
      setResponse(JSON.stringify(await response.json()) || 'OK')
    } catch (e) {
      setStatusCode('Exception');
      if (e instanceof Error) {
        setResponse(e.message);
      } else {
        setResponse(JSON.stringify(e));
      }
    }
  }

  const onSubmit: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault()
    const request = new Request(
      `https://api.display-cms.adapta.ca/${deploy}/${stage}/${selector}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Authorization': `Bearer ${password}`
        },
        body: JSON.stringify({
          title,
          message,
          level: 'WARN'
        })
      }
    );
    try {
      const response = await fetch(request)

      setStatusCode(`${response.status}`)
      setResponse(JSON.stringify(await response.json()) || 'OK')
    } catch (e) {
      setStatusCode('Exception');
      if (e instanceof Error) {
        setResponse(e.message);
      } else {
        setResponse(JSON.stringify(e));
      }
    }
  }

  return (
    <div className="App">
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src={logo}
            alt="adapta logo"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Update your sign message</h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" action="#">
            <div>
                <label htmlFor="deploy" className="block text-sm font-medium text-gray-700">
                  Deployment
                </label>
                <div className="mt-1">
                  <select
                    id="deploy"
                    name="deploy"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onInput={(e) => setDeploy(e.currentTarget.value)}
                  >
                    <option disabled selected> -- select an option -- </option>
                    <option value="grt">Grand River Transit</option>
                    <option value="york">York Region Transit</option>
                  </select>
                </div>
              </div>

              <div>
                <label htmlFor="stage" className="block text-sm font-medium text-gray-700">
                  Stage
                </label>
                <div className="mt-1">
                  <select
                      id="stage"
                      name="stage"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      onInput={(e) => setStage(e.currentTarget.value)}
                    >
                      <option disabled selected> -- select an option -- </option>
                      <option value="development">Development</option>
                      <option value="test">Test</option>
                      <option disabled> -- be careful! -- </option>
                      <option value="prod">Production</option>
                  </select>
                </div>
              </div>
              <div>
                <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                  Title
                </label>
                <div className="mt-1">
                  <input
                    id="title"
                    name="title"
                    type="title"
                    autoComplete="title"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onInput={(e) => setTitle(e.currentTarget.value)}
                  />
                </div>
              </div>

              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                  Message
                </label>
                <div className="mt-1">
                  <textarea
                    id="message"
                    name="message"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onInput={(e) => setMessage(e.currentTarget.value)}
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onInput={(e) => setPassword(e.currentTarget.value)}
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={onSubmit}
                >
                  Submit
                </button>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 mt-2"
                  onClick={onDelete}
                >
                  Delete current message
                </button>
              </div>
            </form>

            {(statusCode || response) && <div>
              <code>{statusCode}: {response}</code>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sentry.withProfiler(App);
